// This is just an example,
// so you can safely delete all default props below

export default {
  failed: 'Action failed',
  success: 'Action was successful',
  index: {
    operation: '操作手冊',
    app_store: '商用授權',
    app_title: 'APP名稱',
    signin: '訪問地址：',
    slogan: '宣傳語',
    server: '請求地址',
    index_title: '倉庫管理平臺',
    webtitle: 'GreaterWMS--開源倉庫管理系統',
    home: '首頁',
    title: 'GreaterWMS',
    title_tip: 'GreaterWMS首頁',
    hide_menu: '收起選單',
    api: 'API檔案',
    translate: '更改語言',
    unread: '未讀消息',
    login: '登入',
    register: '注册',
    login_tip: '輸入你的OPENID和用戶名',
    register_tip: '注册成為管理員',
    logout: '退出登入',
    user_login: '用戶登入',
    admin_login: '管理員登入',
    return_to_login: '返回登入',
    user_center: '用戶中心',
    change_user: '更改用戶',
    view_my_openid: '查看我的OPENID',
    your_openid: '你的OPENID',
    contact_list: '最近聯繫人',
    chat_more: '更多歷史消息',
    chat_no_more: '沒有更多消息',
    chat_send: '發送',
    previous: '上一頁',
    next: '下一頁',
    admin_name: '管理員',
    password: '密碼',
    confirm_password: '確認密碼',
    staff_name: '用戶名',
    cancel: '取消',
    close: '關閉',
    submit: '提交',
    download: '下載',
    updatetitle: '升級就緒',
    updatedesc: '版本已經升級準備就緒',
    update: '馬上更新',
    chart: '報表',
    current_user: '當前用戶'
  },
  Settings: {
    index: '設定',
    server: '服務器',
    equipment: '設備支持'
  },
  menuItem: {
    dashboard: '報表中心',
    inbound: '收貨管理',
    outbound: '發貨管理',
    stock: '庫存管理',
    finance: '財務中心',
    goods: '商品管理',
    baseinfo: '基本設置',
    warehouse: '倉庫設置',
    staff: '用戶管理',
    driver: '司機管理',
    customerdn: '客戶訂單',
    supplierasn: '供應商訂單',
    uploadcenter: '上傳中心 ',
    downloadcenter: '下載中心',
    cloudwarehouse: '萬倉互聯'
  },
  contact: '立即溝通',
  sendmessage: '給他發一條消息',
  send: '發送',
  nomoremessage: '沒有更多消息',
  loadmore: '加載更多',
  new: '新增',
  newtip: '新增一條數據',
  refresh: '刷新',
  refreshtip: '刷新所有數據',
  edit: '編輯這條數據',
  confirmedit: '確認編輯數據',
  canceledit: '取消編輯數據',
  delete: '刪除這條數據',
  deletetip: '此操作不可逆。 ',
  confirmdelete: '確認刪除數據',
  canceldelete: '取消刪除數據',
  download: '下載',
  downloadtip: '下載所有數據',
  frombin: '從庫位',
  movetobin: '移庫',
  putaway: '上架',
  cyclecount: '動態盤點',
  cyclecountrecorder: '盤點記錄',
  search: '關鍵字搜索',
  creater: '創建人',
  createtime: '創建時間',
  updatetime: '更新時間',
  action: '操作',
  previous: '上一頁',
  next: '下一頁',
  no_data: '沒有更多數據',
  submit: '確認',
  cancel: '取消',
  estimate: '預估運費',
  downloadasnlist: '下載列表',
  downloadasndetail: '下載明細',
  downloadasnlisttip: '下載到貨通知書列表',
  downloadasndetailtip: '下載到貨通知書明細',
  printthisasn: '查看到貨通知書',
  confirmdelivery: '確認已經到貨 ',
  finishloading: '確認卸貨完成 ',
  confirmsorted: '確認分揀完成 ',
  downloaddnlist: '下載列表',
  downloaddndetail: '下載明細',
  downloaddnlisttip: '下載發貨單列表',
  downloaddndetailtip: '下載發貨單明細',
  release: '匹配訂單',
  releaseallorder: '全部生成揀貨單',
  releaseorder: '生成揀貨單',
  print: '打印揀貨單',
  printthisdn: '查看和打印發貨單',
  confirmorder: '確認訂單',
  confirmpicked: '確認揀貨完成',
  dispatch: '發貨',
  deletebackorder: '刪除欠貨訂單',
  confirminventoryresults: '確認盤點結果',
  baseinfo: {
    company_info: '公司信息',
    supplier: '供應商',
    customer: '客戶',
    view_company: {
      company_name: '公司名稱',
      company_city: '所在城市',
      company_address: '地址',
      company_contact: '聯繫方式',
      company_manager: '負責人',
      error1: '請輸入公司名稱',
      error2: '請輸入公司所在城市',
      error3: '請輸入公司地址',
      error4: '請輸入公司聯係人',
      error5: '請輸入公司負責人'
    },
    view_supplier: {
      supplier_name: '供應商名稱',
      supplier_city: '所在城市',
      supplier_address: '地址',
      supplier_contact: '聯繫方式',
      supplier_manager: '負責人',
      supplier_level: '供應商等級',
      error1: '請輸入供應商名稱',
      error2: '請輸入供應商所在城市',
      error3: '請輸入供應商地址',
      error4: '請輸入供應商聯係人',
      error5: '請輸入供應商負責人',
      error6: '請輸入供應商級別'
    },
    view_customer: {
      customer_name: '客戶名稱',
      customer_city: '所在城市',
      customer_address: '地址',
      customer_contact: '聯繫方式',
      customer_manager: '負責人',
      customer_level: '客戶等級',
      error1: '請輸入客戶名稱',
      error2: '請輸入客戶所在城市',
      error3: '請輸入客戶地址',
      error4: '請輸入客戶聯係人',
      error5: '請輸入客戶負責人',
      error6: '請輸入客戶級別'
    }
  },
  dashboards: {
    outbound_statements: '出庫報表',
    inbound_statements: '收穫報表',
    inbound_and_outbound_statements: '收發貨報表',
    total_sales: '總銷量',
    category_sales_ranking: '品類銷量排名',
    sales_volume_ranking: '銷售額排名',
    sales_volumes_ranking: '銷售數量排名',
    total_receipts: '縂收貨數',
    category_receipt_ranking: '收貨品類排名',
    receiving_quantity_ranking: '收貨數量排名',
    Receiving_amount_ranking: '收貨額排名',
    view_tradelist: {
      mode_code: '交易方式',
      bin_name: '庫比特名稱',
      goods_code: '商品编码',
      goods_qty: '商品編碼',
      creater: '創建人',
      update_time: '更新時間',
      create_time: '創建時間',
      inbound: '收貨',
      outbound: '发貨'
    }
  },
  finance: {
    capital: '固定資產',
    freight: '運輸費用',
    view_capital: {
      capital_name: '固定資產名稱',
      capital_qty: '數量',
      capital_cost: '金額',
      error1: '請輸入資產名稱',
      error2: '資產數量必須大於0',
      error3: '資產金額必須大於0'
    },
    view_freight: {
      transportation_supplier: '承運商',
      send_city: '始發地',
      receiver_city: '目的地',
      weight_fee: '重量運費',
      volume_fee: '體積運費',
      min_payment: '最小費用',
      error1: '請輸入承運商',
      error2: '請輸入始發城市',
      error3: '請輸入收穫城市',
      error4: '單公斤運費必須大於0',
      error5: '但立方米運費必須大於0',
      error6: '最小運費必須大於0'
    }
  },
  driver: {
    driver: '司機管理',
    dispatchlist: '提貨記錄',
    error1: '請輸入司機名稱',
    error2: '請輸入車牌號',
    error3: '請輸入聯係人',
    view_driver: {
      driver_name: '司機姓名',
      license_plate: '車牌號',
      contact: '聯繫方式'
    },
    view_dispatch: {
      driver_name: '司機姓名',
      dn_code: '發貨單號',
      contact: '聯繫方式'
    }
  },
  upload_center: {
    initializeupload: '初始化上傳',
    uploadfiles: '上傳文件',
    upload: '上傳',
    uploadcustomerfile: '上傳客戶清單',
    uploadgoodslistfile: '上傳商品清單',
    uploadsupplierfile: '上傳供應商清單',
    downloadgoodstemplate: '下載商品模板',
    downloadcustomertemplate: '下載客戶模板',
    downloadsuppliertemplate: '下載供應商模板',
    addupload: '新增上傳'
  },
  download_center: {
    createTime: '創建時間',
    reset: '重置',
    start: '開始',
    end: '結束'
  },
  community_mall: {
    communitymall: '社區商城'
  },
  goods: {
    goods_list: '商品列表',
    unit: '商品單位',
    class: '商品類別',
    color: '商品顏色',
    brand: '商品品牌',
    shape: '商品形狀',
    specs: '商品規格',
    origin: '商品產地',
    view_goodslist: {
      goods_code: '商品編碼',
      goods_desc: '商品描述',
      goods_name: '商品名稱',
      goods_supplier: '供應商',
      goods_weight: '商品重量(單位:克)',
      goods_w: '商品長度(單位:毫米)',
      goods_d: '商品寬度(單位:毫米)',
      goods_h: '商品高度(單位:毫米)',
      unit_volume: '最小單位體積',
      goods_unit: '商品單位',
      goods_class: '商品類別',
      goods_brand: '商品品牌',
      goods_color: '商品顏色',
      goods_shape: '商品形狀',
      goods_specs: '商品規格',
      goods_origin: '商品產地',
      goods_cost: '商品成本',
      goods_price: '商品價格',
      print_goods_label: '打印商品標簽',
      error1: '請輸入商品編碼',
      error2: '請輸入商品描述',
      error3: '請輸入商品的供應商',
      error4: '商品重量必須大於0',
      error5: '商品長度必須大於0',
      error6: '商品寬度必須大於0',
      error7: '商品高度必須大於0',
      error8: '請輸入商品成本',
      error9: '請輸入商品價格'
    },
    view_unit: {
      goods_unit: '商品單位',
      error1: '請輸入商品單位'
    },
    view_class: {
      goods_class: '商品類別',
      error1: '請輸入商品類別'
    },
    view_color: {
      goods_color: '商品顏色',
      error1: '請輸入商品顔色'
    },
    view_brand: {
      goods_brand: '商品品牌',
      error1: '請輸入商品品牌'
    },
    view_shape: {
      goods_shape: '商品形狀',
      error1: '請輸入商品形狀'
    },
    view_specs: {
      goods_specs: '商品規格',
      error1: '請輸入商品規格'
    },
    view_origin: {
      goods_origin: '商品產地',
      error1: '請輸入商品產地'
    }
  },
  inbound: {
    asn: '到貨通知書',
    predeliverystock: '待到貨',
    preloadstock: '待卸貨',
    presortstock: '待分揀',
    sortstock: '已分揀',
    shortage: '來貨短少',
    more: '多到貨',
    asnfinish: '收貨明細',
    asndone: '收貨完成',
    view_sortstock: {
      error1: '請輸入數量，數量必須大於0'
    },
    view_asn: {
      asn_code: '到貨通知書單號',
      asn_status: '到貨通知書狀態',
      goods_qty: '到貨通知書數量',
      goods_actual_qty: '實際到貨數量',
      goods_shortage_qty: '到貨短少數量',
      goods_more_qty: '多到貨數量',
      goods_damage_qty: '到貨破損數量',
      presortstock: '帶分揀數量',
      sorted_qty: '已入庫數量',
      total_weight: '總重量(單位:千克)',
      total_volume: '總體積(單位:立方米)'
    }
  },
  outbound: {
    dn: '發貨單',
    freshorder: '預發貨單',
    neworder: '新發貨單',
    backorder: '欠貨訂單',
    pickstock: '待揀貨',
    pickedstock: '已揀貨',
    pickinglist: '揀貨單',
    shippedstock: '已發貨',
    received: '已簽收',
    pod: '簽收回單',
    view_dn: {
      dn_code: '發貨單號',
      dn_status: '發貨單狀態',
      goods_qty: '訂單數量',
      intransit_qty: '已發貨數量',
      delivery_actual_qty: '實際到貨數量',
      delivery_shortage_qty: '到貨短少',
      delivery_more_qty: '多到貨',
      delivery_damage_qty: '到貨破損',
      total_weight: '總重量(單位:千克)',
      total_volume: '總體積(單位:立方米)',
      customer: '客戶'
    }
  },
  staff: {
    staff: '員工列表',
    check_code: '驗證碼',
    view_staff: {
      staff_name: '員工名稱',
      staff_type: '員工類型',
      error1: '請輸入員工姓名',
      error2: '請輸入員工類型',
      lock: '鎖定',
      unlock: '解鎖'
    }
  },
  stock: {
    stocklist: '庫存列表',
    stockbinlist: '庫位列表',
    emptybin: '空庫位',
    occupiedbin: '有貨庫位',
    view_stocklist: {
      goods_code: '商品編碼',
      goods_desc: '商品描述',
      goods_qty: '總數量',
      onhand_stock: '現有數量',
      can_order_stock: '可被下單數量',
      ordered_stock: '已被下單數量',
      inspect_stock: '質檢數量',
      hold_stock: '鎖貨數量',
      damage_stock: '破損數量',
      asn_stock: '到貨通知書數量',
      dn_stock: '發貨單數量',
      pre_load_stock: '待卸貨數量',
      pre_sort_stock: '待分揀數量',
      sorted_stock: '已分揀數量',
      pick_stock: '待揀貨數量',
      picked_stock: '已揀貨數量',
      back_order_stock: '欠貨數量',
      on_hand_inventory: '現有庫存',
      history_inventory: '當時庫存',
      physical_inventory: '盤點數量',
      difference: '差異',
      cyclecount: '動態盤點',
      recyclecount: '复盤',
      downloadcyclecount: '盤點表',
      cyclecountresult: '確認結果',
      cyclecounttip: '生成動態盤點表',
      recyclecounttip: '生成複盤盤點表',
      downloadcyclecounttip: '下載盤點表',
      cyclecountresulttip: '確認盤點結果',
      daychoice: '日期選擇',
      daychoicetip: '選擇對應日期的盤點表',
      error1: '計數數量必須大於0',
      dateerror: '選擇的日期不正確'
    }
  },
  warehouse: {
    warehouse: '倉庫設置',
    binset: '庫位設置',
    binsize: '庫位尺寸',
    property: '庫位屬性',
    printbin: '打印庫位標簽',
    view_warehouseset: {
      error1: '請輸入倉庫名稱',
      error2: '請輸入倉庫所在城市',
      error3: '請輸入倉庫地址',
      error4: '請輸入倉庫聯係方式給',
      error5: '請輸入倉庫負責人'
    },
    view_warehouse: {
      warehouse_name: '倉庫名稱',
      warehouse_city: '所在城市',
      warehouse_address: '地址',
      warehouse_contact: '聯繫方式',
      warehouse_manager: '負責人',
      square_measure: '可用面積',
      city_search: '都市搜索',
      publish_warehouse: '發佈倉庫',
      Nopublish_warehouse: '召回倉庫'
    },
    view_binset: {
      bin_name: '庫位名稱',
      bin_size: '庫位尺寸',
      bin_property: '庫位屬性',
      empty_label: '空庫位標識',
      error1: '請輸入庫位名稱',
      error2: '請輸入庫位尺寸',
      error3: '請輸入庫位屬性'
    },
    view_binsize: {
      bin_size: '庫位尺寸',
      bin_size_w: '庫位長度(單位:毫米)',
      bin_size_d: '庫位寬度(單位:毫米)',
      bin_size_h: '庫位高度(單位:毫米)',
      error1: '請輸入庫位尺寸',
      error2: '庫位長度必須大於0',
      error3: '庫位寬度必須大於0',
      error4: '庫位高度必須大於0'
    },
    view_property: {
      bin_property: '庫位屬性'
    }
  },
  scan: {
    scan: '掃描',
    scan_asn: 'ASN查詢',
    scan_dn: 'DN查詢',
    scan_sorting: '分揀',
    scan_uptobin: '上架',
    scan_picking: '揀貨',
    scan_shipping: '裝車發貨',
    scan_movetobin: '移庫',
    scan_inventory: '盤點',
    scan_goodsquery: '貨物查詢',
    scan_locationquery: '庫位查詢',
    scan_goods_code: '商品編碼',
    scan_bin_name: '庫比特名稱',
    scan_goods_label: '商品標籤',
    scan_goods_label_error: '商品標籤不存在',
    view_binmove: {
      old_bin_name: '原庫比特名稱',
      new_bin_name: '新庫比特名稱',
      qty: '移庫數量',
      qty_error: '移庫數量不得大於現有數量'
    },
    view_upToBin: {
      goods_actual_qty: '實際到貨數量',
      scan_qty: '已掃描數量',
      scan_qty_error: '掃描數量不得大於到貨數量  '
    },
    view_packing: {
      order_qty: '訂單數量',
      packing_qty: '揀貨數量',
      packing_qty_error: '揀貨數量不得大於訂單數量'
    },
    view_shipping: {
      shipping_code: '發貨單號',
      driver_info: '司機信息',
      license_plate_number: '車牌號',
      name: '姓名',
      contact_info: '聯繫方式'
    }
  },
  handcount: {
    handcount: '單項盤點',
    handcountrecorder: '單項盤點記錄',
    update_time: '盤點時間'
  },
  notice: {
    valerror: '請輸入正確的值',
    unknow_error: '未知錯誤',
    network_error: '網絡異常',
    nullerror: '',
    cyclecounterror: '沒有數據',
    userererror: '用戶名已存在',
    capitalerror: '固定資產名稱已存在',
    valuenullerror: '請填寫完整數據',
    loginerror: '請先登錄',
    goodserror: {
      goods_listerror: '商品編碼已存在',
      goods_uniterror: '商品單位已存在',
      goods_classerror: '商品類別已存在',
      goods_colorerror: '商品顔色已存在',
      goods_branderror: '商品品牌已存在',
      goods_shapeerror: '商品形狀已存在',
      goods_specserror: '商品規格已存在',
      goods_originerror: '商品產地已存在'
    },
    baseinfoerror: {
      companyerror: '公司名稱已存在',
      customererror: '客戶名稱已存在',
      suppliererror: '供應商名稱已存在'
    },
    warehouseerror: {
      binseterror: '庫位名稱已存在',
      binsizeerror: '庫位尺寸已存在'
    },
    mobile_userlogin: {
      notice1: '請輸入您的管理員姓名',
      notice2: '請輸入您的管理員密碼',
      notice3: '請輸入您的員工姓名',
      notice4: '請輸入您的員工校驗碼',
      notice5: '請在設置服務器中輸入您的 Openid',
      notice6: '成功登錄',
      notice7: '用戶或密碼未分配',
      notice8: '員工或檢查代碼未分配'
    },
    mobile_scan: {
      notice1: '二維碼不存在',
      notice2: '編碼不存在',
      notice3: '服務器錯誤',
      notice4: '只有移動端可以掃描'
    },
    mobile_asn: {
      notice1: '到貨通知書列表',
      notice2: '你可以掃描到貨通知書的二維碼，也可以點擊到貨通知書，查看到貨通知書明細，進行操作',
      notice3: '供應商：',
      notice4: '總金額：',
      notice5: '狀態：',
      notice6: '到貨通知書明細',
      notice7: '需要掃描到貨通知書，來獲取到貨通知書明細，你可以掃描貨物碼，或者點擊你想要上架的貨物，來完成貨物上架的操作',
      notice8: '明細',
      notice9: '總金額：',
      notice10: '待上架數量：',
      notice11: '上架數量必須大於0',
      notice12: '上架成功',
      notice13: '請輸入庫位編碼'
    },
    mobile_dn: {
      notice1: '發貨單列表',
      notice2: '你可以掃描到發貨單的二維碼，也可以點擊發貨單，查看發貨單明細，進行操作',
      notice3: '客戶：',
      notice4: '總金額：',
      notice5: '狀態：',
      notice6: '發貨單明細',
      notice7: '發貨單明細，是所有的發貨單，掃描發貨單，可以查看具體發貨單的明細',
      notice8: '明細',
      notice9: '總金額：',
      notice10: '發貨單數量：',
      notice11: '所有的揀貨單明細都在這裡，你也可以掃描具體貨物，或者發貨單，來獲取要操作的揀貨單',
      notice12: '請輸入具體揀貨數量',
      notice13: '揀貨成功',
      notice14: '庫位名稱：',
      notice15: '待揀貨數量：'
    },
    mobile_goodsstock: {
      notice1: '庫存列表',
      notice2: '這裡可以看到所有的庫存信息，點擊可以直接查看庫位的庫存信息',
      notice3: '在手庫存：',
      notice4: '可被下單數量：'
    },
    mobile_binstock: {
      notice1: '庫位庫存',
      notice2: '這裡可以看到所有庫位的庫存信息，點擊可以直接庫位的庫存，進行移庫操作，也可以掃描貨物，查看所有的貨物存儲情況',
      notice3: '庫位編碼：',
      notice4: '存儲數量：',
      notice5: '請輸入庫位編碼',
      notice6: '移庫成功'
    },
    mobile_emptybin: {
      notice1: '空庫位列表',
      notice2: '這裡可以看到所有的空庫位',
      notice3: '庫位屬性：'
    },
    equipment: {
      notice1: '設備支持清單',
      notice2: '這里羅列了所有該APP支持的設備品牌和系統'
    },
    handcount: {
      notice1: '明細',
      notice2: '手動盤點',
      notice3: '現有庫存',
      notice4: '盤點數量',
      notice5: '確認結果',
      notice6: '確認盤點結果',
      notice7: '確認盤點成功',
      notice8: '這裡顯示需要盤點的貨物明細'
    },
    400: '錯誤請求(400)',
    401: '未取得授權(401)',
    403: '拒絕訪問(403)',
    404: '資源不存在(404)',
    405: '該功能被禁用(405)',
    408: '請求超時(408)',
    409: '數據衝突(409)',
    410: '數據已刪除(410)',
    500: '服務器錯誤(500)',
    501: '服務未實現(501)',
    502: '網絡錯誤(502)',
    503: '服務不可用(503)',
    504: '網絡超時(504)',
    505: 'HTTP版本不受支持(505)'
  }
}
