// This is just an example,
// so you can safely delete all default props below

export default {
  failed: 'Action failed',
  success: 'Action was successful',
  index: {
    operation: 'Bedienungsanleitung',
    app_store: 'Kommerzielle Lizenz',
    signin: 'Zugangsadresse:',
    app_title: 'APP-Titel',
    slogan: 'Slogan',
    server: 'Basis-URL anfordern',
    index_title: 'Plattform für das Warehouse Management',
    webtitle: 'GreaterWMS--Open Source Lagerverwaltungs System',
    home: 'Home Page',
    title: 'GreaterWMS',
    title_tip: 'GreaterWMSHome Page',
    hide_menu: 'Menü Zwischenstopp',
    api: 'APIDatei',
    translate: 'Sprache ändern',
    unread: 'Ungelesene Nachricht',
    login: 'Anmeldung',
    register: 'Register',
    login_tip: 'Geben Sie Ihren openid und Benutzernamen ein',
    register_tip: 'Registrieren als Administrator',
    logout: 'Log out',
    user_login: 'Login des Benutzers',
    admin_login: 'Login des Administrators',
    return_to_login: 'Return Login',
    user_center: 'User Center',
    change_user: 'Benutzer ändern',
    view_my_openid: 'Sehen Sie meineOPENID',
    your_openid: 'Ihr openid',
    contact_list: 'Neue Kontakte',
    chat_more: 'Weitere historische Nachrichten',
    chat_no_more: 'keine Neuigkeiten mehr',
    chat_send: 'senden',
    previous: 'Vorherige Seite',
    next: 'Nächste Seite',
    admin_name: 'Administrator',
    password: 'Passwort',
    confirm_password: 'Kennwort bestätigen',
    staff_name: 'Nutzername',
    cancel: 'Abbrechen',
    close: 'Schließung',
    submit: 'einreichen',
    download: 'herunterladen',
    updatetitle: 'Upgrade bereit',
    updatedesc: 'Die Version ist bereit für ein Upgrade',
    update: 'Jetzt aktualisieren',
    chart: 'Prüfbericht',
    current_user: 'Aktueller Benutzer'
  },
  Settings: {
    index: 'Einstellungen',
    server: 'Server',
    equipment: 'Ausrüstungsunterstützung'
  },
  menuItem: {
    dashboard: 'Dashboard',
    inbound: 'Eingehend',
    outbound: 'Ausgehend',
    stock: 'Inventar',
    finance: 'Finanzen',
    goods: 'Warenliste',
    baseinfo: 'Basisinfo',
    warehouse: 'Lager',
    staff: 'Personal',
    driver: 'Fahrer',
    customerdn: 'Kunden-DN',
    supplierasn: 'Lieferanten-ASN',
    uploadcenter: 'Upload-Center',
    downloadcenter: 'Downloadcenter',
    cloudwarehouse: 'Lagerverbindung'
  },
  contact: 'Jetzt kommunizieren',
  sendmessage: 'schick ihm eine Nachricht',
  send: 'senden',
  nomoremessage: 'keine Neuigkeiten mehr',
  loadmore: 'Mehr laden',
  new: 'Hinzufügen',
  newtip: 'Neue Daten hinzufügen',
  refresh: 'Aktualisierung',
  refreshtip: 'Alle Daten aktualisieren',
  edit: 'Diese Daten bearbeiten',
  confirmedit: 'Daten bearbeiten bestätigen',
  canceledit: 'Daten bearbeiten abbrechen',
  delete: 'Diese Daten löschen',
  deletetip: 'Diese Operation ist irreversibel.',
  confirmdelete: 'Daten löschen bestätigen',
  canceldelete: 'Daten wiederherstellen',
  download: 'herunterladen',
  downloadtip: 'Alle Daten herunterladen',
  frombin: 'Vom Lagerort',
  movetobin: 'Transfer',
  putaway: 'auf dem Regal',
  cyclecount: 'Dynamisches Inventar',
  cyclecountrecorder: 'Inventaraufzeichnung',
  search: 'Schlagwortsuche',
  creater: 'Gründer',
  createtime: 'Zeit schaffen',
  updatetime: 'Updatezeit',
  action: 'arbeiten',
  previous: 'Vorherige Seite',
  next: 'Nächste Seite',
  no_data: 'Keine Daten mehr',
  submit: 'bestätigen Sie',
  cancel: 'Abbrechen',
  estimate: 'Voraussichtlicher Versand',
  downloadasnlist: 'Liste herunterladen',
  downloadasndetail: 'Details herunterladen',
  downloadasnlisttip: 'Ankunftsliste herunterladen',
  downloadasndetailtip: 'Details der Ankunftsanzeige herunterladen',
  printthisasn: 'Überprüfen Sie die Ankunftsanzeige',
  confirmdelivery: 'Bestätigung ist eingetroffen',
  finishloading: 'Entladen abgeschlossen bestätigen',
  confirmsorted: 'Sortierung abgeschlossen bestätigen',
  downloaddnlist: 'Liste herunterladen',
  downloaddndetail: 'Details herunterladen',
  downloaddnlisttip: 'Rechnungsliste herunterladen',
  downloaddndetailtip: 'Rechnungsdetails herunterladen',
  release: 'Match-Reihenfolge',
  releaseallorder: 'Alle generieren Auswahlliste',
  releaseorder: 'Auswahlliste erstellen',
  print: 'Auswahlliste drucken',
  printthisdn: 'Rechnungen anzeigen und drucken',
  confirmorder: 'Bestellung bestätigen',
  confirmpicked: 'Kommissionierung abgeschlossen bestätigen',
  dispatch: 'Verladung und Lieferung',
  deletebackorder: 'Rückstand löschen',
  confirminventoryresults: 'Zählergebnis bestätigen',
  baseinfo: {
    company_info: 'Firmeninformation',
    supplier: 'Anbieter',
    customer: 'Kunde',
    view_company: {
      company_name: 'Name der Firma',
      company_city: 'Ort Stadt',
      company_address: 'die Anschrift',
      company_contact: 'Kontaktinformation',
      company_manager: 'Rektor',
      error1: 'Bitte Firmennamen eingeben',
      error2: 'Bitte geben Sie die Stadt ein, in der sich das Unternehmen befindet',
      error3: 'Bitte geben Sie die Firmenadresse ein',
      error4: 'Bitte geben Sie einen Firmenkontakt ein',
      error5: 'Bitte geben Sie den Verantwortlichen des Unternehmens ein'
    },
    view_supplier: {
      supplier_name: 'Name des Anbieters',
      supplier_city: 'Ort Stadt',
      supplier_address: 'die Anschrift',
      supplier_contact: 'Kontaktinformation',
      supplier_manager: 'Rektor',
      supplier_level: 'Lieferantenebene',
      error1: 'Bitte geben Sie den Lieferantennamen ein',
      error2: 'Bitte geben Sie die Stadt ein, in der sich der Lieferant befindet',
      error3: 'Bitte geben Sie die Adresse des Lieferanten ein',
      error4: 'Bitte Kontaktdaten eingeben',
      error5: 'Bitte geben Sie den Verantwortlichen des Lieferanten ein',
      error6: 'Bitte Lieferantenebene eingeben'
    },
    view_customer: {
      customer_name: 'Name des Kunden',
      customer_city: 'Ort Stadt',
      customer_address: 'die Anschrift',
      customer_contact: 'Kontaktinformation',
      customer_manager: 'Rektor',
      customer_level: 'Kundenebene',
      error1: 'Bitte Kundennamen eingeben',
      error2: 'Bitte geben Sie die Stadt ein, in der sich der Kunde befindet',
      error3: 'Bitte Kundenadresse eingeben',
      error4: 'Bitte geben Sie einen Kontakt ein',
      error5: 'Bitte Kontomanager eingeben',
      error6: 'Bitte Kundenebene eingeben'
    }
  },
  dashboards: {
    outbound_statements: 'Ausgehender Bericht',
    inbound_statements: 'Eingangsbericht',
    inbound_and_outbound_statements: 'Eingangs- und Lieferbericht',
    total_sales: 'Gesamtumsatz',
    category_sales_ranking: 'Kategorie Verkaufsranking',
    sales_volume_ranking: 'Verkaufsrang',
    sales_volumes_ranking: 'Anzahl der Verkaufsrangliste',
    total_receipts: 'Gesamteingang',
    category_receipt_ranking: 'Kategorie Quittungsrangfolge',
    receiving_quantity_ranking: 'Belegmengenrang',
    Receiving_amount_ranking: 'Quittung der Quittungen',
    view_tradelist: {
      mode_code: 'Transaktionsmittel',
      bin_name: 'Standortnamen',
      goods_code: 'Warencode',
      goods_qty: 'Menge zur Hand',
      creater: 'Gründer',
      update_time: 'Updatezeit',
      create_time: 'Zeit schaffen',
      inbound: 'Kassenbon',
      outbound: 'Schiff'
    }
  },
  finance: {
    capital: 'Anlagevermögen',
    freight: 'Frachtverwaltung',
    view_capital: {
      capital_name: 'Name des festen Assets',
      capital_qty: 'Menge',
      capital_cost: 'Höhe',
      error1: 'Bitte geben Sie den Assetnamen ein',
      error2: 'Die Anzahl der Assets muss größer als 0 sein',
      error3: 'Der Vermögensbetrag muss größer als 0 sein'
    },
    view_freight: {
      transportation_supplier: 'Träger',
      send_city: 'Ursprungsstadt',
      receiver_city: 'Ankunftsort',
      weight_fee: 'Versand pro Kilo',
      volume_fee: 'Einzelmeter Versand',
      min_payment: 'Mindestversand',
      error1: 'Bitte geben Sie einen Spediteur ein',
      error2: 'Bitte Stadt senden eingeben',
      error3: 'Bitte geben Sie die Empfängerstadt ein',
      error4: 'Die Versandkosten pro Kilogramm müssen größer als 0 sein',
      error5: 'Einzelne Kubikmeter Versandkosten müssen größer als 0 sein',
      error6: 'Mindestversand muss größer als 0 sein'
    }
  },
  driver: {
    driver: 'Fahrerverwaltung',
    dispatchlist: 'Abholdatensatz',
    error1: 'Bitte geben Sie den Namen des Fahrers ein',
    error2: 'Bitte geben Sie das Kennzeichen ein',
    error3: 'Bitte geben Sie einen Kontakt ein',
    view_driver: {
      driver_name: 'Fahrername',
      license_plate: 'Nummernschild',
      contact: 'Kontaktinformation'
    },
    view_dispatch: {
      driver_name: 'Fahrername',
      dn_code: 'Abholauftragsnummer',
      contact: 'Kontaktinformation'
    }
  },
  upload_center: {
    initializeupload: 'Upload initialisieren',
    uploadfiles: 'Daten hochladen',
    upload: 'Hochladen',
    uploadcustomerfile: 'Kundenliste hochladen',
    uploadgoodslistfile: 'Produktformularliste hochladen',
    uploadsupplierfile: 'Lieferantenliste hochladen',
    downloadgoodstemplate: 'Produktvorlage herunterladen',
    downloadcustomertemplate: 'Kundenvorlage herunterladen',
    downloadsuppliertemplate: 'Lieferantenvorlage herunterladen',
    addupload: 'Hochladen hinzufügen'
  },
  download_center: {
    createTime: 'Zeit schaffen',
    reset: 'zurücksetzen',
    start: 'Anfang',
    end: 'Beenden'
  },
  community_mall: {
    communitymall: 'Community-Mall'
  },
  goods: {
    goods_list: 'Produktliste',
    unit: 'Wareneinheit',
    class: 'Artikelkategorie',
    color: 'Produktfarbe',
    brand: 'Produktmarke',
    shape: 'Produktform',
    specs: 'Produktspezifikationen',
    origin: 'Produktherkunft',
    view_goodslist: {
      goods_code: 'Warencode',
      goods_desc: 'Produktbeschreibung',
      goods_name: 'Produktname',
      goods_supplier: 'Anbieter',
      goods_weight: 'Warengewicht (Einheit: Gramm)',
      goods_w: 'Artikellänge (Einheit: mm)',
      goods_d: 'Produktbreite (Einheit: mm)',
      goods_h: 'Warenhöhe (Einheit: mm)',
      unit_volume: 'Mindestvolumen der Einheit',
      goods_unit: 'Wareneinheit',
      goods_class: 'Artikelkategorie',
      goods_brand: 'Produktmarke',
      goods_color: 'Produktfarbe',
      goods_shape: 'Produktform',
      goods_specs: 'Produktspezifikationen',
      goods_origin: 'Produktherkunft',
      goods_cost: 'Warenkosten',
      goods_price: 'Warenpreis',
      print_goods_label: 'Produktetikett drucken',
      error1: 'Bitte geben Sie den Produktcode ein',
      error2: 'Bitte Produktbeschreibung eingeben',
      error3: 'Bitte geben Sie den Lieferanten des Produkts ein',
      error4: 'Artikelgewicht muss größer als 0 sein',
      error5: 'Artikellänge muss größer als 0 sein',
      error6: 'Produktbreite muss größer als 0 sein',
      error7: 'Produkthöhe muss größer als 0 sein',
      error8: 'Bitte Warenkosten eingeben',
      error9: 'Bitte Produktpreis eingeben'
    },
    view_unit: {
      goods_unit: 'Wareneinheit',
      error1: 'Bitte Produkteinheit eingeben'
    },
    view_class: {
      goods_class: 'Artikelkategorie',
      error1: 'Bitte Produktkategorie eingeben'
    },
    view_color: {
      goods_color: 'Produktfarbe',
      error1: 'Bitte Produktfarbe eingeben'
    },
    view_brand: {
      goods_brand: 'Produktmarke',
      error1: 'Bitte Produktmarke eingeben'
    },
    view_shape: {
      goods_shape: 'Produktform',
      error1: 'Bitte Produktform eingeben'
    },
    view_specs: {
      goods_specs: 'Produktspezifikationen',
      error1: 'Bitte Produktspezifikation eingeben'
    },
    view_origin: {
      goods_origin: 'Produktherkunft',
      error1: 'Bitte geben Sie die Herkunft des Produkts ein'
    }
  },
  inbound: {
    asn: 'Eingangsbestätigung',
    predeliverystock: 'ankommen',
    preloadstock: 'Entladen',
    presortstock: 'sortiert werden',
    sortstock: 'sortiert',
    shortage: 'Kurze Ankünfte',
    more: 'Mehr Ankünfte',
    asnfinish: 'Quittungsdetails',
    asndone: 'Eingang abgeschlossen',
    view_sortstock: {
      error1: 'Bitte Menge eingeben, Menge muss größer 0 sein'
    },
    view_asn: {
      asn_code: 'Anmeldenummer',
      asn_status: 'Ankunftsbenachrichtigungsstatus',
      goods_qty: 'Anzahl der Ankunftsmeldungen',
      goods_actual_qty: 'Ist-Eingangsmenge',
      goods_shortage_qty: 'In geringer Menge angekommen',
      goods_more_qty: 'Große Eingangsmenge',
      goods_damage_qty: 'Anzahl der beschädigten Ankünfte',
      presortstock: 'Zu sortierende Nummer',
      sorted_qty: 'Menge auf Lager',
      total_weight: 'Gesamtgewicht (Einheit: kg)',
      total_volume: 'Gesamtvolumen (Einheit: Kubikmeter)'
    }
  },
  outbound: {
    dn: 'Rechnung',
    freshorder: 'Vorabrechnung',
    neworder: 'Neue Rechnung',
    backorder: 'Rückstände',
    pickstock: 'ausgewählt sein',
    pickedstock: 'Ausgewählt',
    pickinglist: 'Details zur Auswahlliste',
    shippedstock: 'Versand',
    received: 'Wurde erhalten',
    pod: 'Quittung unterschreiben',
    view_dn: {
      dn_code: 'Lieferungsnummer',
      dn_status: 'Rechnungsstatus',
      goods_qty: 'Bestellmenge',
      intransit_qty: 'Menge ausgeliefert',
      delivery_actual_qty: 'Ist-Eingangsmenge',
      delivery_shortage_qty: 'Kurze Ankunft',
      delivery_more_qty: 'Mehr Ankünfte',
      delivery_damage_qty: 'Beschädigung bei der Ankunft',
      total_weight: 'Gesamtgewicht (Einheit: kg)',
      total_volume: 'Gesamtvolumen (Einheit: Kubikmeter)',
      customer: 'Kunde'
    }
  },
  staff: {
    staff: 'Mitarbeiterliste',
    check_code: 'Bestätigungs-Code',
    view_staff: {
      staff_name: 'Mitarbeitername',
      staff_type: 'Mitarbeitertyp',
      error1: 'Bitte Mitarbeiternamen eingeben',
      error2: 'Bitte geben Sie den Mitarbeitertyp ein',
      lock: 'Verriegeln',
      unlock: 'Freischalten'
    }
  },
  stock: {
    stocklist: 'Lagerliste',
    stockbinlist: 'Standortliste',
    emptybin: 'Leerer Standort',
    occupiedbin: 'Lagerort',
    view_stocklist: {
      goods_code: 'Warencode',
      goods_desc: 'Produktbeschreibung',
      goods_qty: 'Der Gesamtbetrag',
      onhand_stock: 'Menge zur Hand',
      can_order_stock: 'Platzierbare Menge',
      ordered_stock: 'Anzahl der getätigten Bestellungen',
      inspect_stock: 'Qualitätsprüfmenge',
      hold_stock: 'Gesperrte Menge',
      damage_stock: 'Bruchmenge',
      asn_stock: 'Anzahl der Ankunftsmeldungen',
      dn_stock: 'Rechnungsmenge',
      pre_load_stock: 'zu entladende Menge',
      pre_sort_stock: 'Zu sortierende Nummer',
      sorted_stock: 'Anzahl sortiert',
      pick_stock: 'Zu kommissionierende Menge',
      picked_stock: 'Menge ausgewählt',
      back_order_stock: 'Ausstehende Warenmenge',
      on_hand_inventory: 'Bestandsbestände',
      history_inventory: 'Zur Zeit auf Lager',
      physical_inventory: 'Menge zählen',
      difference: 'Unterschied',
      cyclecount: 'Dynamisches Inventar',
      recyclecount: 'Zurücksetzen',
      downloadcyclecount: 'Inventarliste',
      cyclecountresult: 'Ergebnisse überprüfen',
      cyclecounttip: 'Dynamische Inventartabelle generieren',
      recyclecounttip: 'Checkliste für Lagerauffüllung erstellen',
      downloadcyclecounttip: 'Checkliste herunterladen',
      cyclecountresulttip: 'Zählergebnis bestätigen',
      daychoice: 'Datumsauswahl',
      daychoicetip: 'Wählen Sie die dem Datum entsprechende Inventartabelle aus',
      error1: 'Anzahl der Zählungen muss größer als 0 sein',
      dateerror: 'Falsches Datum ausgewählt'
    }
  },
  warehouse: {
    warehouse: 'Lagereinstellungen',
    binset: 'Lagerstandorteinstellung',
    binsize: 'Lagergröße',
    property: 'Standort-Eigenschaft',
    printbin: 'Standortetikett drucken',
    view_warehouseset: {
      error1: 'Bitte Lagername eingeben',
      error2: 'Bitte geben Sie die Stadt ein, in der sich das Lager befindet',
      error3: 'Bitte Lageradresse eingeben',
      error4: 'Bitte geben Sie die Lagerkontaktinformationen ein',
      error5: 'Bitte geben Sie den Lagerverantwortlichen ein'
    },
    view_warehouse: {
      warehouse_name: 'Lagername',
      warehouse_city: 'Ort Stadt',
      warehouse_address: 'die Anschrift',
      warehouse_contact: 'Kontaktinformation',
      warehouse_manager: 'Rektor',
      square_measure: 'Nutzfläche',
      city_search: 'Stadtsuche',
      publish_warehouse: 'Lagerhaus veröffentlichen',
      Nopublish_warehouse: 'Rückruflager'
    },
    view_binset: {
      bin_name: 'Standortnamen',
      bin_size: 'Lagergröße',
      bin_property: 'Standort-Eigenschaft',
      empty_label: 'Leere Standort-ID',
      error1: 'Bitte Ortsnamen eingeben',
      error2: 'Bitte Ortsnamen eingeben',
      error3: 'Bitte geben Sie das Standortattribut ein'
    },
    view_binsize: {
      bin_size: 'Lagergröße',
      bin_size_w: 'Lagerlänge (Einheit: mm)',
      bin_size_d: 'Lagerbreite (Einheit: mm)',
      bin_size_h: 'Lagerhöhe (Einheit: mm)',
      error1: 'Bitte Ortsnamen eingeben',
      error2: 'Lagerlänge muss größer als 0 sein',
      error3: 'Bestandsbreite muss größer als 0 sein',
      error4: 'Bestandshöhe muss größer als 0 sein'
    },
    view_property: {
      bin_property: 'Eigenschaften von Lagerstandorten'
    }
  },
  scan: {
    scan: 'scannen',
    scan_asn: 'ASN-Abfrage',
    scan_dn: 'DN-Abfrage',
    scan_sorting: 'Sortierung',
    scan_uptobin: 'auf dem Regal',
    scan_picking: 'Auswahl',
    scan_shipping: 'Verladung und Lieferung',
    scan_movetobin: 'Transfer',
    scan_inventory: 'Inventar',
    scan_goodsquery: 'Frachtanfrage',
    scan_locationquery: 'Lagerstandortabfrage',
    scan_goods_code: 'Warencode',
    scan_bin_name: 'Standortnamen',
    scan_goods_label: 'Produktetikett',
    scan_goods_label_error: 'Artikelbezeichnung existiert nicht',
    view_binmove: {
      old_bin_name: 'Ursprünglicher Standortname',
      new_bin_name: 'Neuer Standortname',
      qty: 'Anzahl der umgezogenen Lagerhallen',
      qty_error: 'Die zu bewegende Menge darf die vorhandene Menge nicht'
    },
    view_upToBin: {
      goods_actual_qty: 'Ist-Eingangsmenge',
      scan_qty: 'überschreiten',
      scan_qty_error: 'Anzahl der gescannten'
    },
    view_picking: {
      order_qty: 'Bestellmenge',
      picking_qty: 'Anzahl der Picks',
      picking_qty_error: 'Kommissioniermenge darf nicht größer als Bestellmenge sein'
    },
    view_shipping: {
      shipping_code: 'Lieferungsnummer',
      driver_info: 'Fahrerinformationen',
      license_plate_number: 'Nummernschild',
      name: 'Name',
      contact_info: 'Kontaktinformation'
    }
  },
  handzählung: {
    handcount: 'Einzelzählung',
    handcountrecorder: 'Single-Count-Rekorder',
    update_time: 'Zählzeit'
  },
  notice: {
    valerror: 'Bitte geben Sie den richtigen Wert ein',
    unknow_error: 'unbekannter Fehler',
    network_error: 'Netzwerkanomalie',
    nullerror: '',
    cyclecounterror: 'keine Daten',
    userererror: 'Benutzername existiert bereits',
    capitalerror: 'Name des festen Assets bereits vorhanden',
    valuenullerror: 'Bitte geben Sie die vollständigen Daten ein',
    loginerror: 'Bitte loggen Sie sich zuerst ein',
    goodserror: {
      goods_listerror: 'Warencode existiert bereits',
      goods_uniterror: 'Wareneinheit existiert bereits',
      goods_classerror: 'Artikelkategorie existiert bereits',
      goods_colorerror: 'Artikelfarbe existiert bereits',
      goods_branderror: 'Warenmarke existiert bereits',
      goods_shapeerror: 'Elementform existiert bereits',
      goods_specserror: 'Die Produktspezifikation existiert bereits',
      goods_originerror: 'Produktherkunft existiert bereits'
    },
    baseinfoerror: {
      companyerror: 'Firmenname existiert bereits',
      customererror: 'Kundenname existiert bereits',
      suppliererror: 'Lieferantenname existiert bereits'
    },
    warehouseerror: {
      binseterror: 'Der Standortname existiert bereits',
      binsizeerror: 'Bestandsgröße bereits vorhanden'
    },
    mobile_userlogin: {
      notice1: 'Bitte geben Sie Ihren Admin-Namen ein',
      notice2: 'Bitte geben Sie Ihr Admin-Passwort ein',
      notice3: 'Bitte geben Sie Ihren Mitarbeiternamen ein',
      notice4: 'Bitte geben Sie Ihren Mitarbeiterprüfcode ein',
      notice5: 'Bitte geben Sie Ihre OpenID im Einstellungsserver ein',
      notice6: 'Erfolgreiche Anmeldung',
      notice7: 'Benutzer oder Passwort nicht zugeordnet',
      notice8: 'Staff or Check Code Not Allocation'
    },
    mobile_scan: {
      notice1: 'QR-Code existiert nicht',
      notice2: 'Code existiert nicht',
      notice3: 'Serverfehler',
      notice4: 'Nur Handy kann scannen'
    },
    mobile_asn: {
      notice1: 'ASN-Liste',
      notice2: 'Sie können den QR-Code der Ankunftsbenachrichtigung scannen oder auf die Ankunftsbenachrichtigung klicken, um die Details der Ankunftsbenachrichtigung anzuzeigen und zu bearbeiten',
      notice3: 'Lieferant:',
      notice4: 'Gesamtbetrag:',
      notice5: 'Status:',
      notice6: 'Details der Ankunftsmitteilung',
      notice7: 'Sie müssen die Ankunftsbenachrichtigung scannen, um die Details der Ankunftsbenachrichtigung zu erhalten. Sie können den Frachtcode scannen oder auf die Waren klicken, die Sie in die Regale stellen möchten, um den Vorgang der Waren in den Regalen abzuschließen',
      notice8: 'Details',
      notice9: 'Gesamtbetrag:',
      notice10: 'Aufzulistende Nummer:',
      notice11: 'Die Anzahl der Einträge muss größer als 0 sein',
      notice12: 'Erfolgreiche Auflistung',
      notice13: 'Bitte geben Sie den Ortscode ein'
    },
    mobile_dn: {
      notice1: 'DN-Liste',
      notice2: 'Sie können den QR-Code des DN-Auftrags scannen oder auf den DN-Auftrag klicken, um die Details des DN anzuzeigen und Operationen auszuführen',
      notice3: 'Kunde:',
      notice4: 'Gesamtbetrag:',
      notice5: 'Status:',
      notice6: 'DN-Details',
      notice7: 'Die Details des DN sind alle Rechnungen. Scannen Sie die DN-Nummer, um die Details des spezifischen DN anzuzeigen',
      notice8: 'Details',
      notice9: 'Gesamtbetrag:',
      notice10: 'Rechnungsmenge:',
      Notice11: 'Hier finden Sie alle Details der Kommissionierliste, Sie können auch die spezifischen Waren oder den DN scannen, um die zu bedienende Kommissionierliste zu erhalten',
      notice12: 'Bitte geben Sie die konkrete Kommissioniermenge ein',
      notice13: 'Erfolgreiche Kommissionierung',
      notice14: 'Bin-Name:',
      notice15: 'Zu pflückende Menge:'
    },
    mobile_goodsstock: {
      notice1: 'Lagerliste',
      notice2: 'Hier können Sie alle Inventarinformationen sehen, klicken Sie hier, um die Inventarinformationen direkt anzuzeigen',
      notice3: 'Lagerbestand:',
      notice4: 'Kann bestellt werden:'
    },
    mobile_binstock: {
      notice1: 'Bin Stock List',
      Notice2: 'Hier können Sie die Bestandsinformationen aller Lagerstandorte sehen, klicken, um direkt auf den Bestand des Lagerstandorts zuzugreifen, den Lagerumlagerungsvorgang durchzuführen oder die Waren zu scannen, um den Lagerstatus aller Waren zu überprüfen',
      notice3: 'Bin-Name:',
      notice4: 'Lagermenge:',
      notice5: 'Bitte geben Sie den Bin-Namen ein',
      notice6: 'Repository erfolgreich verschoben'
    },
    mobile_emptybin: {
      notice1: 'Bin-Liste leeren',
      notice2: 'Hier können Sie alle leeren Orte sehen',
      notice3: 'Bestandsbehältereigenschaft:'
    },
    equipment: {
       notice1: 'Liste der unterstützten Geräte',
       notice2: 'Hier sind alle von der APP unterstützten Gerätemarken und Systeme aufgelistet'
     },
    handcount: {
      notice1: 'Details',
      notice2: 'Manuelle Zählung',
      notice3: 'Vorrat',
      notice4: 'Quantität zählen',
      notice5: 'Ergebnis bestätigen',
      notice6: 'Bestätige das Zählergebnis',
      notice7: 'Erfolgreich bestätigtes Zählergebnis',
      notice8: 'Hier werden die Details der Waren angezeigt, die gezählt werden müssen'
    },
    400: 'Schlechte Anfrage (400)',
    401: 'Nicht autorisiert (401)',
    403: 'Zugriff verweigert (403)',
    404: 'Ressource existiert nicht (404)',
    405: 'Diese Funktion ist deaktiviert (405)',
    408: 'Zeitüberschreitung der Anforderung (408)',
    409: 'Datenkonflikt (409)',
    410: 'Daten gelöscht (410)',
    500: 'Serverfehler (500)',
    501: 'Dienst nicht implementiert (501)',
    502: 'Netzwerkfehler (502)',
    503: 'Dienst nicht verfügbar (503)',
    504: 'Netzwerkzeitüberschreitung (504)',
    505: 'HTTP-Version nicht unterstützt (505)'
  }
}
