// This is just an example,
// so you can safely delete all default props below

export default {
  failed: 'Action failed',
  success: 'Action was successful',
  index: {
    operation: "Mode d'emploi",
    app_store: 'Licence commerciale',
    signin: "Adresse d'accès:",
    app_title: "Titre de l'application",
    slogan: 'Slogan',
    server: 'requête URL de base',
    index_title: "Plateforme de gestion d'entrepôt'",
    webtitle: "GreaterWMS - Système de gestion d'entrepôt Open Source",
    home: 'page de garde',
    title: 'GreaterWMS',
    title_tip: 'GreaterWMSpage de garde',
    hide_menu: 'Fermer le menu',
    api: 'Documentation API',
    translate: 'changer de langue',
    unread: 'Messages non lus',
    login: 'connexion',
    register: "'S'inscrire'",
    login_tip: "'Entrez votre OPENID et votre nom d'utilisateur'",
    register_tip: "'S'inscrire en tant qu'administrateur'",
    logout: 'Se déconnecter',
    user_login: 'Utilisateur en ligne',
    admin_login: 'Connexion administrateur',
    return_to_login: 'retourner à la connexion',
    user_center: 'Centre utilisateur',
    change_user: "'Changer d'utilisateur'",
    view_my_openid: 'Voir mon OPENID',
    your_openid: 'votre OPENID',
    contact_list: 'Contacts récents',
    chat_more: "'Plus d'actualités historiques'",
    chat_no_more: 'Plus de nouvelles',
    chat_send: 'envoyer',
    previous: 'Page précédente',
    next: 'Page suivante',
    admin_name: 'administrateur',
    password: 'le mot de passe',
    confirm_password: 'Confirmez le mot de passe',
    staff_name: "'Nom d'utilisateur'",
    cancel: 'Annuler',
    close: 'fermeture',
    submit: 'nous faire parvenir',
    download: 'Télécharger',
    updatetitle: 'Mise à niveau prête',
    updatedesc: 'La version est prête pour la mise à niveau ',
    update: 'Mettez à jour maintenant',
    chart: 'Reportage',
    current_user: 'Utilisateur actuel'
  },
  Settings: {
    index: 'Réglages',
    server: 'Serveur',
    equipment: "Soutien à l 'équipement"
  },
  menuItem: {
    dashboard: 'Tableau de bord',
    inbound: 'entrant',
    outbound: 'sortant',
    stock: 'inventaire',
    finance: 'Finances',
    goods: 'Liste Marchandises',
    baseinfo: 'Informations de base',
    warehouse: 'Entrepôt',
    staff: 'Personnel',
    driver: 'Chauffeur',
    customerdn: 'DN client',
    supplierasn: 'Fournisseur ASN',
    uploadcenter: 'Centre de téléchargement',
    downloadcenter: 'Centre de téléchargement',
    cloudwarehouse: "Interconnexion d'entrepôt"
  },
  contact: 'Communiquer immédiatement',
  sendmessage: 'Envoyez-lui un message',
  send: 'envoyer',
  nomoremessage: 'Plus de nouvelles',
  loadmore: 'charger plus',
  new: 'Ajouter',
  newtip: 'Ajouter une nouvelle donnée',
  refresh: 'rafraîchir',
  refreshtip: 'Actualiser toutes les données',
  edit: 'Modifier ces données',
  confirmedit: "'Confirmer les données d'édition'",
  canceledit: 'Annuler la modification des données',
  delete: 'Supprimer ces données',
  deletetip: 'Cette opération est irréversible. ',
  confirmdelete: 'Confirmer la suppression des données',
  canceldelete: 'Récupérer les données',
  download: 'Télécharger',
  downloadtip: 'Télécharger toutes les données',
  frombin: "'Depuis l'emplacement de stockage'",
  movetobin: 'Transfert',
  putaway: "'sur l'étagère'",
  cyclecount: 'Inventaire dynamique',
  cyclecountrecorder: "'Fiche d'inventaire'",
  search: 'Recherche par mot clé',
  creater: 'fondateur',
  createtime: 'créer du temps',
  updatetime: 'Temps de mise à jour',
  action: 'fonctionner',
  previous: 'Page précédente',
  next: 'Page suivante',
  no_data: 'Plus de données',
  submit: 'confirmer',
  cancel: 'Annuler',
  estimate: 'Frais de livraison estimés',
  downloadasnlist: 'Télécharger la liste',
  downloadasndetail: 'Télécharger les détails',
  downloadasnlisttip: "'Télécharger la liste des avis d'arrivée'",
  downloadasndetailtip: "'Télécharger les détails de l'avis d'arrivée'",
  printthisasn: "'Vérifier l'avis d'arrivée'",
  confirmdelivery: 'La confirmation est arrivée',
  finishloading: 'Confirmer le déchargement terminé',
  confirmsorted: 'Confirmer le tri terminé',
  downloaddnlist: 'Télécharger la liste',
  downloaddndetail: 'Télécharger les détails',
  downloaddnlisttip: 'Télécharger la liste des factures',
  downloaddndetailtip: 'Télécharger les détails de la facture',
  release: 'ordre de correspondance',
  releaseallorder: 'Tous génèrent une liste de sélection',
  releaseorder: 'Générer une liste de sélection',
  print: 'Imprimer la liste de sélection',
  printthisdn: 'Afficher et imprimer les factures',
  confirmorder: 'Confirmer la commande',
  confirmpicked: 'Confirmer la cueillette terminée',
  dispatch: 'Chargement et livraison',
  deletebackorder: 'Supprimer la commande en souffrance',
  confirminventoryresults: 'Confirmer le résultat du comptage',
  baseinfo: {
    company_info: 'Informations sur la société',
    supplier: 'fournisseur',
    customer: 'client',
    view_company: {
      company_name: 'Nom de la compagnie',
      company_city: 'Lieu de la ville',
      company_address: 'adresse',
      company_contact: 'Informations de contact',
      company_manager: 'principal',
      error1: "'Veuillez saisir le nom de l'entreprise'",
      error2: "'Veuillez indiquer la ville où se situe l'entreprise'",
      error3: "'Veuillez saisir l'adresse de l'entreprise'",
      error4: "'Veuillez entrer le contact de l'entreprise'",
      error5: "'Veuillez indiquer le responsable de l'entreprise'"
    },
    view_supplier: {
      supplier_name: 'Nom du fournisseur',
      supplier_city: 'Lieu de la ville',
      supplier_address: 'adresse',
      supplier_contact: 'Informations de contact',
      supplier_manager: 'principal',
      supplier_level: 'Niveau fournisseur',
      error1: 'Veuillez entrer le nom du fournisseur',
      error2: 'Veuillez entrer la ville où se trouve le fournisseur',
      error3: "'Veuillez entrer l'adresse du fournisseur'",
      error4: 'Veuillez entrer vos coordonnées',
      error5: 'Veuillez saisir le responsable du fournisseur',
      error6: 'Veuillez entrer le niveau du fournisseur'
    },
    view_customer: {
      customer_name: 'nom du client',
      customer_city: 'Lieu de la ville',
      customer_address: 'adresse',
      customer_contact: 'Informations de contact',
      customer_manager: 'principal',
      customer_level: 'Niveau client',
      error1: 'Veuillez saisir le nom du client',
      error2: 'Veuillez entrer la ville où se trouve le client',
      error3: "'Veuillez saisir l'adresse du client'",
      error4: 'Veuillez entrer un contact',
      error5: 'Veuillez entrer le gestionnaire de compte',
      error6: 'Veuillez entrer le niveau de client'
    }
  },
  dashboards: {
    outbound_statements: 'Rapport sortant',
    inbound_statements: 'Rapport de réception',
    inbound_and_outbound_statements: 'Procès-verbal de réception et de livraison',
    total_sales: 'Ventes totales',
    category_sales_ranking: 'Classement des ventes par catégorie',
    sales_volume_ranking: 'Classement des ventes',
    sales_volumes_ranking: 'Nombre de classement des ventes',
    total_receipts: 'Réception totale',
    category_receipt_ranking: 'Classement des reçus par catégorie',
    receiving_quantity_ranking: 'Rang de la quantité reçue',
    Receiving_amount_ranking: 'Classement des reçus',
    view_tradelist: {
      mode_code: 'moyens de transaction',
      bin_name: 'Nom de la localisation',
      goods_code: 'Code marchandise',
      goods_qty: 'Quantité disponible',
      creater: 'fondateur',
      update_time: 'Temps de mise à jour',
      create_time: 'créer du temps',
      inbound: 'Reçu',
      outbound: 'Bateau'
    }
  },
  finance: {
    capital: 'Immobilisations',
    freight: 'Gestion du fret',
    view_capital: {
      capital_name: "'Nom de l'actif fixe'",
      capital_qty: 'quantité',
      capital_cost: 'Quantité',
      error1: "'Veuillez saisir le nom de l'actif'",
      error2: "'Le nombre d'actifs doit être supérieur à 0'",
      error3: "'Le montant de l'actif doit être supérieur à 0'"
    },
    view_freight: {
      transportation_supplier: 'Transporteur',
      send_city: "'Ville d'origine'",
      receiver_city: "'ville d'arrivée'",
      weight_fee: 'Livraison au kilo',
      volume_fee: 'Expédition au mètre cube',
      min_payment: 'Livraison minimum',
      error1: 'Veuillez entrer un transporteur',
      error2: "'Veuillez entrer la ville d'envoi'",
      error3: 'Veuillez entrer la ville de destination',
      error4: "'Le coût d'expédition par kilogramme doit être supérieur à 0'",
      error5: "'Le coût d'expédition d'un mètre cube doit être supérieur à 0'",
      error6: "'Le minimum d'expédition doit être supérieur à 0'"
    }
  },
  driver: {
    driver: 'Gestion des chauffeurs',
    dispatchlist: 'Enregistrement de ramassage',
    error1: 'Veuillez entrer le nom du conducteur',
    error2: "'Veuillez saisir le numéro de plaque d'immatriculation'",
    error3: 'Veuillez entrer un contact',
    view_driver: {
      driver_name: 'nom du conducteur',
      license_plate: "'Plaque d'immatriculation'",
      contact: 'Informations de contact'
    },
    view_dispatch: {
      driver_name: 'nom du conducteur',
      dn_code: 'Numéro de commande de retrait',
      contact: 'Informations de contact'
    }
  },
  upload_center: {
    initializeupload: 'Initialiser le téléchargement',
    uploadfiles: 'télécharger des fichiers',
    upload: 'télécharger',
    uploadcustomerfile: 'Télécharger la liste des clients',
    uploadgoodslistfile: 'Télécharger la liste des formulaires de produits',
    uploadsupplierfile: 'Télécharger la liste des fournisseurs',
    downloadgoodstemplate: 'Télécharger le modèle de produit',
    downloadcustomertemplate: 'Télécharger le modèle client',
    downloadsuppliertemplate: 'Télécharger le modèle de fournisseur',
    addupload: 'Ajouter un téléchargement'
  },
  download_center: {
    createTime: 'créer du temps',
    reset: 'réinitialiser',
    start: 'Démarrer',
    end: 'Finir'
  },
  community_mall: {
    communitymall: 'Centre commercial communautaire'
  },
  goods: {
    goods_list: 'Liste de produits',
    unit: 'Unité de marchandise',
    class: "'Catégorie d'article'",
    color: 'couleur du produit',
    brand: 'Marque de produit',
    shape: 'Forme du produit',
    specs: 'Spécifications du produit',
    origin: 'Origine du produit',
    view_goodslist: {
      goods_code: 'Code marchandise',
      goods_desc: 'Description du produit',
      goods_name: 'nom du produit',
      goods_supplier: 'fournisseur',
      goods_weight: 'Poids de la marchandise (unité : grammes)',
      goods_w: "'Longueur de l'article (unité : mm)'",
      goods_d: 'Largeur du produit (unité : mm)',
      goods_h: 'Hauteur de la marchandise (unité : mm)',
      unit_volume: 'Volume unitaire minimum',
      goods_unit: 'Unité de marchandise',
      goods_class: "'Catégorie d'article'",
      goods_brand: 'Marque de produit',
      goods_color: 'couleur du produit',
      goods_shape: 'Forme du produit',
      goods_specs: 'Spécifications du produit',
      goods_origin: 'Origine du produit',
      goods_cost: 'Coût de la marchandise',
      goods_price: 'Prix de la marchandise',
      print_goods_label: "'Imprimer l'étiquette du produit'",
      error1: 'Veuillez saisir le code produit',
      error2: 'Veuillez saisir la description du produit',
      error3: 'Veuillez entrer le fournisseur du produit',
      error4: "'Le poids de l'article doit être supérieur à 0'",
      error5: "'La longueur de l'article doit être supérieure à 0'",
      error6: 'La largeur du produit doit être supérieure à 0',
      error7: 'La hauteur du produit doit être supérieure à 0',
      error8: 'Veuillez saisir le coût des marchandises',
      error9: 'Veuillez saisir le prix du produit'
    },
    view_unit: {
      goods_unit: 'Unité de marchandise',
      error1: "'Veuillez saisir l'unité de produit'"
    },
    view_class: {
      goods_class: "'Catégorie d'article'",
      error1: 'Veuillez entrer la catégorie de produit'
    },
    view_color: {
      goods_color: 'couleur du produit',
      error1: 'Veuillez saisir la couleur du produit'
    },
    view_brand: {
      goods_brand: 'Marque de produit',
      error1: 'Veuillez saisir la marque du produit'
    },
    view_shape: {
      goods_shape: 'Forme du produit',
      error1: 'Veuillez saisir la forme du produit'
    },
    view_specs: {
      goods_specs: 'Spécifications du produit',
      error1: 'Veuillez saisir les spécifications du produit'
    },
    view_origin: {
      goods_origin: 'Origine du produit',
      error1: "'Veuillez entrer l'origine du produit'"
    }
  },
  inbound: {
    asn: "'Avis d'arrivée'",
    predeliverystock: 'arriver',
    preloadstock: 'À décharger',
    presortstock: 'à trier',
    sortstock: 'trié',
    shortage: 'Pénurie de marchandises entrantes',
    more: "'Plus d'arrivées'",
    asnfinish: 'Détails du reçu',
    asndone: 'Reçu complété',
    view_sortstock: {
      error1: 'Veuillez entrer la quantité, la quantité doit être supérieure à 0'
    },
    view_asn: {
      asn_code: "'Numéro d'avis d'arrivée'",
      asn_status: "'Statut de notification d'arrivée'",
      goods_qty: "'Nombre d'avis d'arrivée'",
      goods_actual_qty: "'Quantité d'arrivée réelle'",
      goods_shortage_qty: 'Arrivé en petite quantité',
      goods_more_qty: "'Grande quantité d'arrivée'",
      goods_damage_qty: "'Nombre d'arrivées endommagées'",
      presortstock: 'Numéro à trier',
      sorted_qty: 'La quantité en dépôt',
      total_weight: 'Poids total (unité : kg)',
      total_volume: 'Volume total (unité : mètre cube)'
    }
  },
  outbound: {
    dn: "'Facture d'achat'",
    freshorder: 'Pré-facture',
    neworder: 'Nouvelle facture',
    backorder: 'Réservations',
    pickstock: 'à choisir',
    pickedstock: 'Choisi',
    pickinglist: 'Détails de la liste de sélection',
    shippedstock: 'Expédié',
    received: 'Ont été reçus',
    pod: 'Signer le reçu',
    view_dn: {
      dn_code: "'numéro d'expédition'",
      dn_status: 'État de la facture',
      goods_qty: 'quantité de commande',
      intransit_qty: 'Quantité expédiée',
      delivery_actual_qty: "'Quantité d'arrivée réelle'",
      delivery_shortage_qty: 'Arrivée courte',
      delivery_more_qty: "'Plus d'arrivées'",
      delivery_damage_qty: 'Arrivé endommagé',
      total_weight: 'Poids total (unité : kg)',
      total_volume: 'Volume total (unité : mètre cube)',
      customer: 'client'
    }
  },
  staff: {
    staff: 'Liste des employés',
    check_code: 'Code de vérification',
    view_staff: {
      staff_name: "'Nom de l'employé'",
      staff_type: "'Type d'employé'",
      error1: "'Veuillez entrer le nom de l'employé'",
      error2: "'Veuillez entrer le type d'employé'",
      lock: 'verrouillage',
      unlock: 'ouvrir'
    }
  },
  stock: {
    stocklist: 'Liste des stocks',
    stockbinlist: 'Liste des emplacements',
    emptybin: 'Emplacement vide',
    occupiedbin: 'Emplacement en stock',
    view_stocklist: {
      goods_code: 'Code marchandise',
      goods_desc: 'Description du produit',
      goods_qty: 'Le montant total',
      onhand_stock: 'Quantité disponible',
      can_order_stock: 'Quantité pouvant être placée',
      ordered_stock: 'Nombre de commandes passées',
      inspect_stock: 'Quantité de contrôle qualité',
      hold_stock: 'Quantité bloquée',
      damage_stock: 'Quantité de rupture',
      asn_stock: "'Nombre d'avis d'arrivée'",
      dn_stock: 'Quantité de la facture',
      pre_load_stock: 'Quantité à décharger',
      pre_sort_stock: 'Numéro à trier',
      sorted_stock: 'Numéro trié',
      pick_stock: 'Quantité à cueillir',
      picked_stock: 'Quantité choisie',
      back_order_stock: 'Montant des marchandises dues',
      on_hand_inventory: 'actions existantes',
      history_inventory: "'En stock à l'époque'",
      physical_inventory: 'Compter la quantité',
      difference: 'différence',
      cyclecount: 'Inventaire dynamique',
      recyclecount: 'Réinitialiser',
      downloadcyclecount: "'Liste d'inventaire'",
      cyclecountresult: 'vérifier les résultats',
      cyclecounttip: "'Générer une table d'inventaire dynamique'",
      recyclecounttip: 'Générer une liste de contrôle de réapprovisionnement',
      downloadcyclecounttip: 'Télécharger la liste de contrôle',
      cyclecountresulttip: 'Confirmer le résultat du comptage',
      daychoice: 'Sélection de date',
      daychoicetip: "'Sélectionner la table d'inventaire correspondant à la date'",
      error1: 'Le nombre de comptes doit être supérieur à 0',
      dateerror: 'Date sélectionnée incorrecte'
    }
  },
  warehouse: {
    warehouse: "'Paramètres de l'entrepôt'",
    binset: "'Réglage de l'emplacement du stock'",
    binsize: 'Taille du stock',
    property: "'Propriété de l'emplacement'",
    printbin: "'Imprimer l'étiquette d'emplacement'",
    view_warehouseset: {
      error1: "'Veuillez entrer le nom de l'entrepôt'",
      error2: "'Veuillez entrer la ville où se trouve l'entrepôt'",
      error3: "'Veuillez entrer l'adresse de l'entrepôt'",
      error4: "'Veuillez saisir les coordonnées de l'entrepôt'",
      error5: "'Veuillez entrer la personne en charge de l'entrepôt'"
    },
    view_warehouse: {
      warehouse_name: "'nom de l'entrepôt'",
      warehouse_city: 'Lieu de la ville',
      warehouse_address: 'adresse',
      warehouse_contact: 'Informations de contact',
      warehouse_manager: 'principal',
      square_measure: 'Zone disponible',
      city_search: 'Recherche de ville',
      publish_warehouse: 'Publier un entrepôt',
      Nopublish_warehouse: "Rappel de l'entrepôt"
    },
    view_binset: {
      bin_name: 'Nom de la localisation',
      bin_size: 'Taille du stock',
      bin_property: "'Propriété de l'emplacement'",
      empty_label: "'Identifiant d'emplacement vide'",
      error1: "'Veuillez entrer le nom de l'emplacement'",
      error2: "'Veuillez saisir la taille de l'emplacement'",
      error3: "'Veuillez saisir l'attribut de localisation'"
    },
    view_binsize: {
      bin_size: 'Taille du stock',
      bin_size_w: 'Longueur de stock (unité : mm)',
      bin_size_d: 'Largeur du stock (unité : mm)',
      bin_size_h: 'Hauteur du stock (unité : mm)',
      error1: "'Veuillez saisir la taille de l'emplacement'",
      error2: 'La longueur du stock doit être supérieure à 0',
      error3: 'La largeur du brut doit être supérieure à 0',
      error4: 'La hauteur du stock doit être supérieure à 0'
    },
    view_property: {
      bin_property: "'Propriétés de l'emplacement de stock'"
    }
  },
  scan: {
    scan: 'balayage',
    scan_asn: 'Requête ASN',
    scan_dn: 'Requête DN',
    scan_sorting: 'tri',
    scan_uptobin: "'sur l'étagère'",
    scan_picking: 'cueillette',
    scan_shipping: 'Chargement et livraison',
    scan_movetobin: 'Transfert',
    scan_inventory: 'inventaire',
    scan_goodsquery: 'Requête de fret',
    scan_locationquery: "'Requête d'emplacement de stock'",
    scan_goods_code: 'Code marchandise',
    scan_bin_name: 'Nom de la localisation',
    scan_goods_label: 'Étiquette du produit',
    scan_goods_label_error: "'L'étiquette de l'article n'existe pas'",
    view_binmove: {
      old_bin_name: "'Nom de l'emplacement d'origine'",
      new_bin_name: "'Nouveau nom de l'emplacement'",
      qty: "'Nombre d'entrepôts déplacés'",
      qty_error: 'La quantité à déplacer ne doit pas être supérieure à la quantité existante'
    },
    view_upToBin: {
      goods_actual_qty: "'Quantité d'arrivée réelle'",
      scan_qty: 'Nombre de scannés',
      scan_qty_error: "'Le nombre de scans ne doit pas être supérieur au nombre d'arrivées'"
    },
    view_picking: {
      order_qty: 'quantité de commande',
      picking_qty: 'Nombre de sélections',
      picking_qty_error: 'La quantité de prélèvement ne doit pas être supérieure à la quantité de commande'
    },
    view_shipping: {
      shipping_code: "'numéro d'expédition'",
      driver_info: 'informations sur le conducteur',
      license_plate_number: "'Plaque d'immatriculation'",
      name: 'Nom',
      contact_info: 'Informations de contact'
    }
  },
  handcount: {
    handcount: 'Comptage unique',
    handcountrecorder: 'Enregistreur de compte unique',
    update_time: 'Compter le temps'
  },
  notice: {
    valerror: 'Veuillez entrer la valeur correcte',
    unknow_error: 'erreur inconnue',
    network_error: 'anomalie de réseau',
    nullerror: '',
    cyclecounterror: 'pas de données',
    userererror: "'Ce nom d'utilisateur existe déjà'",
    capitalerror: "'Le nom de l'actif fixe existe déjà'",
    valuenullerror: 'Veuillez remplir les données complètes',
    loginerror: "'s'il vous plait Connectez-vous d'abord'",
    goodserror: {
      goods_listerror: 'Le code produit existe déjà',
      goods_uniterror: "'L'unité de commodité existe déjà'",
      goods_classerror: "'La catégorie d'article existe déjà'",
      goods_colorerror: "'La couleur de l'article existe déjà'",
      goods_branderror: 'La marque de produits de base existe déjà',
      goods_shapeerror: "'La forme de l'élément existe déjà'",
      goods_specserror: 'La spécification du produit existe déjà',
      goods_originerror: "'L'origine du produit existe déjà'"
    },
    baseinfoerror: {
      companyerror: "'Le nom de l'entreprise existe déjà'",
      customererror: 'Le nom du client existe déjà',
      suppliererror: 'Le nom du fournisseur existe déjà'
    },
    warehouseerror: {
      binseterror: "'Le nom de l'emplacement existe déjà'",
      binsizeerror: 'La taille du stock existe déjà'
    },
    mobile_userlogin: {
      notice1: "Veuillez saisir votre nom d'administrateur",
      notice2: 'Veuillez saisir votre mot de passe administrateur',
      notice3: 'Veuillez saisir le nom de votre personnel',
      notice4: 'Veuillez saisir votre code de vérification du personnel',
      notice5: 'Veuillez saisir votre Openid dans le serveur de paramètres',
      notice6: 'Connexion réussie',
      notice7: 'Utilisateur ou mot de passe non attribué',
      notice8: 'Personnel ou code de vérification non attribué'
    },
    scan_mobile: {
      notice1: "Le code QR n'existe pas",
      notice2: "Le code n'existe pas",
      notice3: 'Erreur de serveur',
      notice4: 'Seul mobile peut scanner'
    },
    mobile_asn: {
      notice1: 'Liste ASN',
      notice2: "Vous pouvez scanner le code QR de l'avis d'arrivée, ou cliquer sur l'avis d'arrivée pour afficher les détails de l'avis d'arrivée et opérer",
      notice3: 'Fournisseur:',
      notice4: 'Montant total:',
      notice5: 'Statut:',
      notice6: "Détails de l'avis d'arrivée",
      notice7: "Vous devez scanner l'avis d'arrivée pour obtenir les détails de l'avis d'arrivée. Vous pouvez scanner le code de fret ou cliquer sur les marchandises que vous souhaitez mettre en rayon pour terminer l'opération des marchandises en rayon",
      notice8: 'Détails',
      notice9: 'Montant total:',
      notice10: 'Numéro à lister:',
      notice11: "Le nombre d'annonces doit être supérieur à 0",
      notice12: 'Inscription réussie',
      notice13: 'Veuillez saisir le code de localisation'
    },
    mobile_dn: {
      notice1: 'Liste DN',
      notice2: 'Vous pouvez scanner le code QR de la commande DN, ou cliquer sur la commande DN pour afficher les détails du DN et effectuer des opérations',
      notice3: 'Client:',
      notice4: 'Montant total:',
      notice5: 'Statut:',
      notice6: 'Détails DN',
      notice7: 'Les détails du DN sont toutes des factures. Scannez le numéro DN pour afficher les détails du DN spécifique',
      notice8: 'Détails',
      notice9: 'Montant total:',
      notice10: 'Quantité facturée:',
      notice11: 'Tous les détails de la liste de préparation sont ici, vous pouvez également scanner les marchandises spécifiques, ou le DN pour faire fonctionner la liste de préparation',
      notice12: 'Veuillez entrer la quantité spécifique à cueillir',
      notice13: 'Pickage réussi',
      notice14: 'Nom du bac :',
      notice15: 'Quantité à prélever:'
    },
    mobile_goodsstock: {
      notice1: 'Liste des stocks',
      notice2: "Ici, vous pouvez voir toutes les informations d'inventaire, cliquez pour afficher directement les informations d'inventaire",
      notice3: 'Stock disponible:',
      notice4: 'Le stock commandé peut-il :'
    },
    mobile_binstock: {
      notice1: 'Bin Stock List',
      notice2: "Ici, vous pouvez voir les informations d'inventaire de tous les emplacements d'entrepôt, cliquer pour accéder directement à l'inventaire de l'emplacement d'entrepôt, effectuer l'opération de transfert d'entrepôt ou scanner les marchandises pour vérifier l'état de stockage de toutes les marchandises",
      notice3: 'Nom du bac:',
      notice4: 'Quantité de stockage:',
      notice5: 'Veuillez entrer le nom du bac',
      notice6: 'Référentiel déplacé avec succès'
    },
    mobile_emptybin: {
      notice1: 'Vider la liste des corbeilles',
      notice2: 'Ici vous pouvez voir tous les emplacements vides',
      notice3: 'Propriété Stock Bin:'
    },
    equipment: {
       notice1: 'Liste des équipements pris en charge',
       notice2: "Toutes les marques d'appareils et les systèmes pris en charge par l'APP sont répertoriés ici"
     },
    handcount: {
      notice1: 'Détails',
      notice2: 'Comptage manuel',
      notice3: 'Stock disponible',
      notice4: 'Quantité de comptage',
      notice5: 'Confirmer le résultat',
      notice6: 'Confirmer le résultat du comptage',
      notice7: 'Résultat de comptage confirmé réussi',
      notice8: 'Voici les détails des marchandises qui doivent être comptées'
    },
    400: 'Mauvaise demande (400)',
    401: 'Non autorisé (401)',
    403: 'Accès refusé (403)',
    404: "'La ressource n'existe pas (404)'",
    405: 'Cette fonctionnalité est désactivée (405)',
    408: 'Demande expirée (408)',
    409: 'Conflit de données (409)',
    410: 'Données supprimées (410)',
    500: 'Erreur du serveur (500)',
    501: 'Service non implémenté (501)',
    502: 'Erreur réseau (502)',
    503: 'Service indisponible (503)',
    504: 'Réseau expiré (504)',
    505: 'Version HTTP non prise en charge (505)'
  }
}
